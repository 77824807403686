
<template>
    <div>
        <div class="compatible-page">
            <section class="blue-part">
                <div class="ckt-logo">
                    <img class="ckt-logo_img" src="../../assets/imgs/f7d45b1a38949a10662333c0a48c0cbb.png">
                    <hr class="vertical-bar">
                    <p class="logo-text">
                        海量版权资源
                        <br>
                        满足您所有需求
                    </p>
                </div>
                <p class="page-title">
                    您当前的浏览器兼容性较差
                </p>
                <p class="page-tips">
                    为保证您的使用体验，建议您使用谷歌浏览器或者360浏览器急速模式进行访问。谢谢！。
                </p>
                <!-- <ul class="qrcode-group">
                <li class="qrcode-item">
                    <img class="qrcode-img" src="./static/img/compatiblePage_qrcode_app.png">
                    <p class="qrcode-title">
                        下载创客贴APP
                    </p>
                </li>
                <li class="qrcode-item">
                    <img class="qrcode-img" src="./static/img/compatiblePage_qrcode_xcx.png">
                    <p class="qrcode-title">
                        微信小程序
                    </p>
                </li>
            </ul> -->
            </section>
            <section class="white-part">
                <p class="browser-tips">
                    您也可以更新至以下浏览器的
                    <b>
                        最新版本（仅限极速模式）：
                    </b>
                </p>
                <div class="browser-group">
                    <a class="browser-item" href="https://www.google.cn/chrome/">
                        <div class="browser-img">
                        </div>
                        <p class="browser-title">
                            谷歌浏览器
                        </p>
                    </a>
                    <a class="browser-item" href="https://browser.360.cn/ee/">
                        <div class="browser-img img-360">
                        </div>
                        <p class="browser-title">
                            360极速浏览器
                        </p>
                    </a>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
// body,
// html {
//     margin: 0;
//     background: #F5F5FA;
//     height: 100%;
//     width: 100%;
// }

.compatible-page {
    height: 100%;
    width: 100%;
    height: 100vh;
    background: #F5F5FA
}

.compatible-page ul,
.compatible-page li {
    margin: 0;
    padding: 0;
    height: 100vh;
    list-style-type: none
}

.blue-part {
    padding: 48px 48px 135px 48px;
    background: url('../../assets/imgs/compatiblePage_bg.png') no-repeat;
    background-size: cover;
    background-position: bottom
}

.ckt-logo {
    display: flex;
    align-items: flex-end
}

.ckt-logo_img {
    width: 140px;
}

.vertical-bar {
    width: 1px;
    height: 34px;
    border: 0;
    background: #fff;
    margin: 0 14px
}

.logo-text {
    font-size: 14px;
    color: #fff;
    margin: 0
}

.page-title {
    margin: 32px 0;
    font-size: 34px;
    text-align: center;
    color: #fff
}

.page-tips {
    font-size: 16px;
    text-align: center;
    color: #fff;
    margin: 0 0 72px 0
}

.qrcode-group {
    display: flex;
    justify-content: center
}

.qrcode-item:first-child {
    margin-right: 48px
}

.qrcode-img {
    width: 150px;
    height: 150px
}

.qrcode-title {
    font-size: 14px;
    margin: 14px 0 0 0;
    text-align: center;
    color: #fff
}

.white-part {
    padding: 30px 0 75px
}

.browser-tips {
    font-size: 16px;
    color: #1b2337;
    text-align: center;
    margin-bottom: 34px
}

.browser-group {
    display: flex;
    justify-content: center
}

.browser-item {
    width: 120px;
    height: 120px;
    background: #fff;
    border-radius: 8px;
    position: relative;
    cursor: pointer;
    text-decoration: none
}

.browser-item {
    margin-right: 30px
}

.browser-img {
    display: block;
    margin: 15px auto;
    width: 62px;
    height: 62px;
    background-image: url('../../assets/imgs/compatiblePage_browser.png?v=2021001');
    background-repeat: no-repeat;
    background-size: cover;
    transition: all .5s;
    transform: translateY(0px);
}

.browser-img:hover {
    transform: translateY(-4px);
}

.img-qq {
    background-position-x: -62px
}

.img-sogou {
    background-position-x: -124px
}

.img-360 {
    background-position-x: -188px
}

.browser-title {
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: #1b2337;
    margin: 0
}

.browser-item:hover .browser-img {
    transition: top .2s
}
</style>